@font-face{
  font-family: 'KiaLight';
  src: url('../src/assets/fuentes/KiaSignatureLight.ttf')
  format('truetype')
}
@font-face{
  font-family: 'kiaBold';
  src:url('../src/assets/fuentes/KiaSignatureBold.ttf')
  format('truetype')
}

@font-face{
  font-family: 'kiaMedium';
  src:url('../src/assets/fuentes/KiaSignatureRegular.ttf')
  format('truetype')
}

body {
  /* margin: 0; */
  font-family: "kiaLight", -apple-system, BlinkMacSystemFont,  "KiaMedium", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui.button {
  font-family: 'kiaMedium'
}

.ui.menu .active.item {
  background: rgba(0,0,0,.05);
  color:"#BB162B" !important;
  font-weight: 400;
  -webkit-box-shadow: none;
  box-shadow: none;
}

code {
  font-family: "KiaLight", "KiaMedium", "KiaBold" source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5 {
  font-family: 'kiaBold';
}
.ui.header{
  font-family: 'kiaBold';
}

.file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.segment-image {
  border: none;
}

.ui.secondary.pointing.menu {
  margin-left: 0;
  margin-right: 0;
  border-bottom: 2px solid transparent;
}

.ui.secondary.pointing.menu .item {
  border-bottom-color: transparent;
  border-bottom-style: none;
  border-top-color: transparent;
  border-top-style: solid;
  border-radius: 0;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin: 0 0.5em;
  padding: 0.1em 0em;
  border-top-width: 3px !important;
  -webkit-transition: color .5s ease;
  transition: color .5s ease;
  color: rgba(0,0,0,0.2);
  font-weight: bold;
}

.ui.secondary.pointing.menu .active.item {
  background-color: transparent;
  box-shadow: none;
  border-color: #bb162b;
  font-weight: bold;
  color: rgba(0,0,0,.95);
}

.ui.basic.button:hover, .ui.basic.buttons .button:hover {
  background: #bb162b!important;
  color: #FFFFFF!important;
  border-color: #bb162b !important;
  -webkit-box-shadow: none !important;
  box-shadow:none !important;
}

.ui.basic.button:focus, .ui.basic.buttons .button:focus {
  background: #BB162B!important;
  color: #FFFFFF!important;
  -webkit-box-shadow: 0 0 0 1px rgba(34,36,38,.35) inset, 0 0 0 0 rgba(34,36,38,.15) inset;
  box-shadow: 0 0 0 1px rgba(34,36,38,.35) inset, 0 0 0 0 rgba(34,36,38,.15) inset;
}

.ui.primary.button:hover, .ui.primary.buttons .button:hover {
  background: #bb162b!important;
  color: #FFFFFF!important;
  border-color: #bb162b !important;
  -webkit-box-shadow: none !important;
  box-shadow:none !important;
}

.ui.primary.button:focus, .ui.primary.buttons .button:focus {
  background: #BB162B!important;
  color: #FFFFFF!important;
  -webkit-box-shadow: 0 0 0 1px rgba(34,36,38,.35) inset, 0 0 0 0 rgba(34,36,38,.15) inset;
  box-shadow: 0 0 0 1px rgba(34,36,38,.35) inset, 0 0 0 0 rgba(34,36,38,.15) inset;
}

.ui.dropdown .menu {
  cursor: auto;
  position: absolute;
  display: none;
  outline: 0;
  top: 100%;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  margin: 0;
  padding: 20 20;
  background: #fff;
  font-size: 1em;
  text-shadow: none;
  text-align: left;
  -webkit-box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
  box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0rem;
  -webkit-transition: opacity .1s ease;
  transition: opacity .1s ease;
  z-index: 11;
  will-change: transform,opacity;
}

.ui.dropdown .menu>.item {
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;
  /* text */
  border-top: none;
  line-height: 1em;
  color: rgba(0,0,0,.87);
  padding: .78571429rem 1.14285714rem!important;
  font-size: 1rem;
  text-transform: none;
  font-weight: 400;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-touch-callout: none;
}