.ui.menu .active.item {
    background: rgba(0,0,0,.05);
    color:"#BB162B" !important;
    font-weight: 400;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
    font-family: 'kiaLight';
  }
  .ui.form {
    font-family: 'kiaMedium';
  }
  
  .ui.form .field.field input:-webkit-autofill {
    font-family: 'kiaMedium';
  }
  
  input {
    font-family: 'kiaBold' !important;
    border-radius: 0 !important;
    line-height: 1rem !important;
    letter-spacing: 1.3px;
  }

  .ui.selection.dropdown {
    font-family: 'kiaBold' !important;
    cursor: pointer;
    word-wrap: break-word;
    line-height: 2em;
    letter-spacing: 1.3px;
    white-space: normal;
    outline: 0;
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
    min-width: 14em;
    /* min-height: 2.71428571em; */
    background: #fff;
    display: inline-block;
    /* padding: .78571429em 2.1em .78571429em 1em; */
    color: rgba(0,0,0,.87);
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 0 !important;
}

.ui.selection.dropdown>.delete.icon, .ui.selection.dropdown>.dropdown.icon, .ui.selection.dropdown>.search.icon {
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 1.5em !important;
  right: 1em;
  z-index: 3;
  margin: -0.785714em;
  padding: .91666667em;
  opacity: .8;
  -webkit-transition: opacity .1s ease;
  transition: opacity .1s ease;
}
  
  .con_tit:after {
    content: '';
    display: block;
    width: 70px;
    height: 2px;
    background: #bb162b;
    margin-top: 18px;
  }
  
  .ui.menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 1rem 0;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    background: #fff;
    font-weight: 400;
    border: 1px solid rgba(34,36,38,.15);
    -webkit-box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.0);
    border-radius: .28571429rem;
    min-height: 30px;
  }
  
  .ui.steps .step.active {
    cursor: auto;
    background: #BB162C;
    color: #FFFFFF;
  }
  
  .ui.steps .step.active .title {
    color: #FFFFFF;
  }
  .ui.steps .step .title {
    font-family: 'KiaMediun','Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #454545;
  }
  
  .ui.steps .step.active .description {
    font-family: 'KiaBold';
    font-size: 18px;
    color: #FFFFFF;
  }
  
  .ui.menu {
    font-family: 'KiaMedium';
    font-weight: 'bold';
    text-transform: 'uppercase';
  }
  .item {
    text-transform: 'uppercase';
  
  }
  
  textarea {
    font-family: 'KiaLight';
  }
  
  .ui.steps .step .description {
    font-family: 'KiaBold';
    font-size: 18px;
    color: #454545;
  }
  
  .ui.steps .step.active:after {
    background: #bb162b;
  }
  
  .ui.secondary.pointing.menu .active.item {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #bb162b;
    font-weight: 700;
    color: rgba(0,0,0,.95);
  }

  .ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 0;
    outline: 0;
    -webkit-appearance: none;
    /* tap-highlight-color: rgba(255,255,255,0); */
    line-height: 1em;
    padding: 1em 1em !important;
    font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    -webkit-box-shadow: 0 0 0 0 transparent inset;
    box-shadow: 0 0 0 0 transparent inset;
    -webkit-transition: color .1s ease,border-color .1s ease;
    transition: color .1s ease,border-color .1s ease;
}